<template>
  <router-view/>
</template>

<style lang="scss">
* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body {
  height: 100%;
  min-height: 100%;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

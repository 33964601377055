import StorageService from '@/support/services/storage'
import WebsocketService from "@/support/services/websocket";
import DataService from "@/support/services/data";

export default {
    namespaced: true,
    state: () => ({
        current: null,
        bins: [],
        paused: false,
        requests: [],
        ignoredRequests: 0,
        selectedRequest: null
    }),
    getters: {
        current(state) {
            const binIndex = DataService.getIndex({
                array: state.bins,
                data: {hash: state.current},
                identifier: 'hash'
            })

            return state.bins[binIndex]
        },
        bins(state) {
            return state.bins
        },
        ignoredRequests(state) {
            return state.ignoredRequests
        },
        paused(state) {
            return state.paused
        },
        requests(state) {
            return state.requests
        },
        selectedRequest(state) {
            return state.selectedRequest
        }
    },
    actions: {
        deleteRequest({state, dispatch, commit}, {request}) {
            commit('DELETE_REQUEST', request)

            if (state.selectedRequest && request.hash === state.selectedRequest.hash)
                dispatch('selectRequest', {request: null})

        },
        incrementIgnoredRequests({commit}) {
            commit('INCREMENT_IGNORED_REQUESTS')
        },
        async insertBin({state, dispatch, commit}, {bin, current = true}) {
            await StorageService.local.get('bins')
                .then(async (bins) => await StorageService.local.set('bins', [bin, ...bins]))
                .catch(async () => await StorageService.local.set('bins', [bin]))
                .finally(() => {
                    commit('INSERT_BIN', bin)

                    if (current)
                        dispatch('setCurrent', {hash: bin.hash})
                })
        },
        insertRequest({state, commit}, {request}) {
            commit('INSERT_REQUEST', request)
        },
        resetRequests({dispatch, commit}) {
            commit('RESET_REQUESTS')

            dispatch('selectRequest', {request: null})
        },
        async setCurrent({state, dispatch, commit}, {hash}) {
            if (state.current)
                WebsocketService.socket()
                    .leave(`bin.${state.current}`)

            await StorageService.local.set('current_bin', hash)

            dispatch('resetRequests')

            WebsocketService.socket()
                .channel(`bin.${hash}`)
                .listen('.request.received', ({request}) => state.paused ?
                    dispatch('incrementIgnoredRequests') :
                    dispatch('insertRequest', {request})
                )

            commit('SET_CURRENT', hash)
        },
        selectRequest({commit}, {request}) {
            commit('SELECT_REQUEST', request)
        },
        switchPaused({state, commit}) {
            commit('SWITCH_PAUSED')

            if (state.paused)
                commit('RESET_IGNORED_REQUESTS')
        }
    },
    mutations: {
        DELETE_REQUEST(state, request) {
            DataService.delete({
                array: state.requests,
                data: request,
                identifier: 'hash'
            })
        },
        INCREMENT_IGNORED_REQUESTS(state) {
            state.ignoredRequests++
        },
        INSERT_BIN(state, bin) {
            DataService.insert({
                array: state.bins,
                data: bin,
                identifier: 'hash'
            })
        },
        INSERT_REQUEST(state, request) {
            DataService.insert({
                array: state.requests,
                data: request,
                identifier: 'hash'
            })
        },
        RESET_IGNORED_REQUESTS(state) {
            state.ignoredRequests = 0
        },
        RESET_REQUESTS(state) {
            state.requests = []
        },
        SELECT_REQUEST(state, request) {
            state.selectedRequest = request
        },
        SET_CURRENT(state, hash) {
            state.current = hash
        },
        SWITCH_PAUSED(state) {
            state.paused = !state.paused
        }
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/tailwind.css'

import HttpService from '@/support/services/http'
HttpService.init(process.env.VUE_APP_API_ROOT)

import WebsocketService from '@/support/services/websocket'
WebsocketService.init()

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')

const resolveKey = (key) => {
    return `bin_${key}`
}

const get = (storage, key) => {
    return new Promise(async (resolve, reject) => {
        const value = JSON.parse(storage.getItem(resolveKey(key)))

        value ? resolve(value) : reject(false)
    })
}

const set = (storage, key, value = null) => {
    return new Promise((resolve, reject) => {
        storage.setItem(resolveKey(key), JSON.stringify(value))

        resolve(true)
    })
}

const remove = (storage, key) => {
    return new Promise((resolve, reject) => {
        storage.removeItem(resolveKey(key))

        resolve(true)
    })
}

const StorageService = {
    local: {
        get: async (key) => await get(localStorage, key),
        set: async (key, value) => await set(localStorage, key, value),
        remove: async (key) => await remove(localStorage, key)
    },
    session: {
        get: async (key) => await get(sessionStorage, key),
        set: async (key, value) => await set(sessionStorage, key, value),
        remove: async (key) => await remove(sessionStorage, key)
    }
}

export default StorageService
import axios from 'axios'

const HttpService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL
    },
    // setHeader() {
    //     axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
    // },
    // removeHeader() {
    //     axios.defaults.headers.common = {}
    // },
    get(url, config) {
        return axios.get(url, config)
    },
    post(url, data, config) {
        return axios.post(url, data, config)
    }
}

export default HttpService

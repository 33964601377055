import {get as lodashGet} from 'lodash'

const DataService = {
  exists({array, data, identifier = 'id'}) {
    const itemIndex = this.getIndex({array, data, identifier})

    return itemIndex > -1
  },
  getIndex({array, data, identifier = 'id'}) {
    return array.findIndex(item => lodashGet(item, identifier) === (typeof data === 'object' ? lodashGet(data, identifier) : data))
  },
  insert({array, data, identifier = 'id', start = true, limit = null}) {
    if (data instanceof Array) {
      data.forEach(item => this.insert({array, item, identifier, start}))
    } else {
      this.exists({array, data, identifier}) ?
        this.update({array, data, identifier}) :
        (start ? array.unshift(data) : array.push(data))
    }

    if (typeof limit === 'number' && array.length > limit)
      start ? array.pop() : array.shift()
  },
  update({array, data, identifier = 'id'}) {
    const itemIndex = this.getIndex({array, data, identifier})

    if (itemIndex > -1) array.splice(itemIndex, 1, data)
  },
  delete({array, data, identifier = 'id'}) {
    let itemIndex = this.getIndex({array, data, identifier})

    if (itemIndex > -1) array.splice(itemIndex, 1)
  }
}

export default DataService

import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

const constants = {
    websocketEncrypted: true,
    websocketDisableStats: true,
};

let socket = null

const WebsocketService = {
    init() {
        socket = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_WS_KEY,
            cluster: process.env.VUE_APP_WS_CLUSTER,
            forceTLS: false,
            encrypted: constants.websocketEncrypted,
            wsHost: process.env.VUE_APP_WS_HOST,
            wsPort: process.env.VUE_APP_WS_PORT,
            wssPort: process.env.VUE_APP_WS_PORT,
            disableStats: constants.websocketDisableStats,
            enabledTransports: ['ws', 'wss'],
        })

        socket.connector.pusher.connect()
    },
    socket() {
        return socket
    },
    close() {
        if (socket) {
            socket.connector.pusher.disconnect()
        }
    }
}

export default WebsocketService